<template>
  <div
    id="redesign"
    class="flex flex-col items-center w-full bg-[#c9abce] px-20 sm:px-40 xl:px-0 py-80 md:pb-0 md:pt-128"
  >
    <div
      class="flex flex-col gap-24 w-full md:max-w-[calc(90vw)] xl:max-w-[1480px] h-full"
    >
      <h1 class="max-w-[680px] xl:max-w-[780px]">
        {{ $t("process.title") }}
      </h1>

      <div
        class="flex md:grid md:grid-cols-[290px_1fr] gap-20 sm:gap-40 w-full max-w-full overflow-hidden"
      >
        <div
          class="shrink-0 flex flex-col gap-12 w-full md:max-w-[290px] md:pb-40"
        >
          <div
            v-for="(section, i) in sections"
            :key="i"
            class="cursor-pointer transition-opacity hover:opacity-100 border-b border-dark text-14 sm:text-16 md:text-[18px]"
            :class="[currentSection === i ? 'pb-0 md:pb-12' : 'pb-12']"
            @click="swiperInstance?.slideTo(i)"
          >
            <div
              class="flex items-center justify-between gap-24 sm:gap-80 lg:gap-96"
            >
              <div class="flex gap-12">
                <div class="font-bold md:text-24">{{ section.label }}</div>
              </div>
            </div>

            <div :class="currentSection === i ? 'mt-16' : 'hidden'">
              {{ section.description || "" }}
            </div>

            <img
              :src="section.src || ''"
              class="md:hidden w-full h-256 sm:h-[400px] rounded-t-lg md:rounded-lg lg:rounded-xl object-contain aspect-video"
              :class="currentSection === i ? 'mt-20' : 'hidden'"
            />
          </div>
        </div>

        <div
          class="grow hidden md:flex md:flex-col w-[60vw] lg:w-[70vw] max-w-[1100px] h-full"
        >
          <ClientOnly>
            <SwiperContainer
              :initial-slide="currentSection"
              :slides-per-view="1"
              :space-between="24"
              :centered-slides="true"
              class="w-full max-w-full h-full"
              @swiper="(swiper) => (swiperInstance = swiper)"
              @slideChange="(swiper) => (currentSection = swiper.realIndex)"
            >
              <SwiperSlide v-for="(section, i) in sections" :key="i">
                <img
                  :src="section.src || ''"
                  class="w-full h-full object-contain object-right-bottom"
                />
              </SwiperSlide>
            </SwiperContainer>
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from "swiper";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/vue";

import processFirstSteps from "~/assets/img/landingpage/process-first-steps.webp";
import processLaunch from "~/assets/img/landingpage/process-launch.webp";
import processMaintenance from "~/assets/img/landingpage/process-maintenance.webp";
import processProject from "~/assets/img/landingpage/process-project.webp";

const swiperInstance = ref<Swiper | undefined>(undefined);

const { t } = useNuxtApp().$i18n;
const currentSection = ref(0);
const sections = computed(() => [
  {
    src: processFirstSteps,
    label: t("process.beginning.title"),
    description: t("process.beginning.description"),
  },
  {
    src: processProject,
    label: t("process.project.title"),
    description: t("process.project.description"),
  },
  {
    src: processLaunch,
    label: t("process.launch.title"),
    description: t("process.launch.description"),
  },
  {
    src: processMaintenance,
    label: t("process.longterm.title"),
    description: t("process.longterm.description"),
  },
]);
</script>

<style lang="scss" scoped>
.swiper-slide {
  @apply w-[60vw] lg:w-[70vw] max-w-[1100px];
}
</style>
