<template>
  <div id="faq" class="flex flex-col items-center w-full px-20 sm:px-40">
    <div class="w-full md:max-w-[calc(90vw)] xl:max-w-[1480px]">
      <h2 class="max-w-[780px]">{{ $t("faq.title") }}</h2>

      <div class="flex lg:gap-40">
        <div class="flex flex-col w-full max-w-[836px] h-full">
          <div class="flex flex-col gap-16 w-full max-w-[50rem]">
            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :label="$t('faq.question.agency.label')"
            >
              <div v-for="text in $tm('faq.question.agency.text')">
                {{ text }}
              </div>

              <div class="text-14">
                * {{ $t("faq.question.agency.disclaimer") }}
              </div>
            </UiExpandSection>

            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :label="$t('faq.question.duration.label')"
            >
              <div v-for="text in $tm('faq.question.duration.text')">
                {{ text }}
              </div>
            </UiExpandSection>

            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :label="$t('faq.question.results.label')"
            >
              <div v-for="text in $tm('faq.question.results.text')">
                {{ text }}
              </div>
            </UiExpandSection>

            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :label="$t('faq.question.guarantee.label')"
            >
              <div v-for="text in $tm('faq.question.guarantee.text')">
                {{ text }}
              </div>
            </UiExpandSection>

            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :id="pageSectionId"
              :label="$t('faq.question.pages.label')"
            >
              <div
                v-for="text in $tm('faq.question.pages.text')"
                v-html="text"
              ></div>
            </UiExpandSection>

            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :id="designSectionId"
              :label="$t('faq.question.design.label')"
            >
              <div
                v-for="text in $tm('faq.question.design.text')"
                v-html="text"
              ></div>
            </UiExpandSection>

            <UiExpandSection
              v-model:selected-section="selectedSectionId"
              :label="$t('faq.question.updates.label')"
            >
              <div
                v-for="text in $tm('faq.question.updates.text')"
                v-html="text"
              ></div>
            </UiExpandSection>
          </div>
        </div>

        <img
          :src="faq"
          class="hidden md:block shrink-0 h-512 lg:h-[700px] xl:h-fit w-512 xl:w-[800px] object-contain md:-mr-80 lg:mr-0 lg:-mt-160 xl:-mt-256"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import faq from "~/assets/img/landingpage/faq.webp";

const selectedSectionId = ref("");
const pageSectionId = "faq-what-is-a-page-design";
const designSectionId = "faq-design-updates";

const route = useRoute();
const fullPath = computed(() => route.fullPath);
watchEffect(() => {
  if (!fullPath.value.includes(pageSectionId)) return;
  selectedSectionId.value = pageSectionId;
});

watchEffect(() => {
  if (!fullPath.value.includes(designSectionId)) return;
  selectedSectionId.value = designSectionId;
});
</script>

<style lang="scss" scoped>
ul {
  @apply mt-8;
}
li {
  list-style: disc;
  @apply ml-24 mb-8 last:mb-0;
}
</style>
