<template>
  <div
    class="relative flex flex-col items-center w-full h-[300px] md:h-[400px] lg:h-[540px] mt-64 sm:mt-80"
  >
    <div class="relative w-full overflow-hidden fadeaway-gradient">
      <div class="w-full shift-to-left">
        <div
          class="flex items-center gap-8 w-full slider"
          :style="{ transform: `translateX(${-scrollAmount}px)` }"
        >
          <img
            v-for="(image, i) in [...images, ...images, ...images]"
            :key="i"
            :src="image"
            class="shrink-0 h-[300px] md:h-[400px] lg:h-[540px] w-[300px] md:w-[400px] lg:w-[540px] rounded-xl md:rounded-[64px] aspect-square"
            height="400"
            width="400"
          />
        </div>
      </div>
    </div>

    <SharedLink
      url="/"
      hash="#pricing"
      class="group absolute -bottom-48 md:-bottom-64 lg:-bottom-80 right-20 lg:right-80 xl:right-512 z-10 h-fit w-fit fade-in-move-up-3"
    >
      <div>
        <IconsIconBadge
          class="h-128 w-128 md:h-160 md:w-160 transition-transform group-hover:rotate-45"
        />

        <div
          class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center text-white font-special leading-[1] -rotate-12"
        >
          <div>{{ $t("hero.from") }}</div>
          <div class="text-24">{{ getPackageInfo("starter").price }}/mo</div>
        </div>
      </div>
    </SharedLink>
  </div>
</template>

<script lang="ts" setup>
import beaurie from "~/assets/img/references/beaurie.webp";
import gem from "~/assets/img/references/gem.webp";
import HD from "~/assets/img/references/humandolls.webp";
import LK from "~/assets/img/references/lohnkonzepte.webp";
import wildlife from "~/assets/img/references/wildlife.webp";
import { getPackageInfo } from "~/lib/pricing/pricing-packages";

const images = [gem, beaurie, HD, wildlife, LK];

const scrollAmount = ref(0);
function handleScroll() {
  const maxScrollY = 800;
  const scrollRatio = 0.2;

  scrollAmount.value = Math.max(
    -200,
    Math.min(maxScrollY, window.scrollY * scrollRatio)
  );
}
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.slider {
  transition-property: all;
  transition-timing-function: ease-out;
  transition-duration: 750ms;
}

.fadeaway-gradient {
  @media screen and (min-width: 1920px) {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 12.5%,
      rgb(0, 0, 0) 87.5%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.shift-to-left {
  animation: shiftSlowly 0.75s ease-in-out forwards;
}
@keyframes shiftSlowly {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(-40px);
  }
}
</style>
